import React, { useEffect, useState } from "react";
import jsonAutocomplete from "json-autocomplete";
import { SSE } from "sse.js";
import Cookies from "js-cookie";
import { formatActivityKey } from "../utils/ActivityUtils";
import Loading from "./Loading";

const StreamChatGPT = ({ tripData, onStreamingFinished }) => {
  // state to store response data
  const [response, setResponse] = useState({ role: "", content: "" });
  const [completedResponse, setCompletedResponse] = useState("");
  const [parsedResponse, setParsedResponse] = useState(null);

  // state to store activity data, including hyperlinks
  const [hyperlinks, setHyperlinks] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // set of error message to exclude
  const excludedErrorPatterns = [
    "Expected ':' after property name in JSON",
    "Expected ',' or '}' after property value in JSON",
    "SyntaxError: Unexpected end of JSON input",
  ];

  // Function to check if the error message contains any excluded patterns
  const isErrorExcluded = (errorMessage) => {
    return excludedErrorPatterns.some((pattern) =>
      errorMessage.includes(pattern),
    );
  };

  // HOOKS
  // useEffect hook to monitor response changes and set completedResponse
  useEffect(() => {
    // Check if response is valid and needs autocomplete
    if (response && response.content) {
      const completedContent = jsonAutocomplete(response.content);
      setCompletedResponse(completedContent);
    }
  }, [response]);

  // useEffect hook to parse completedResponse when it updates and set parsedResponse
  useEffect(() => {
    const parseJSON = (jsonString) => {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        if (!isErrorExcluded(e.message)) {
          console.error("JSON string:", jsonString);
          console.error("Error parsing JSON:", e);
          // Here, just log the error without updating the state
        }
        return null; // Return null, but don't use this to update state
      }
    };

    const parsedData = parseJSON(completedResponse);
    if (parsedData !== null) {
      // Update state only if parsing was successful
      setParsedResponse(parsedData);
    }
  }, [completedResponse]);

  // useEffect hook is used to check for changes in hyperlinks and updated content
  useEffect(() => {
    if (hyperlinks.length > 0) {
      const updatedContent = replaceTermsWithHyperlinks(
        response.content,
        hyperlinks,
      );
      setResponse((prev) => ({ ...prev, content: updatedContent }));
    }
  }, [hyperlinks, response.content]);

  // useEffect hook to fetch data from the server when page is loaded
  useEffect(() => {
    console.log("StreamChatGPT component mounted");

    console.log("Streaming started");

    // Process the data to create hyperlinks
    const newHyperlinks = createHyperlinks(tripData);

    // Update the state
    setHyperlinks((prevHyperlinks) => {
      return [...prevHyperlinks, ...newHyperlinks];
    });

    // cross-site request forgery token
    const csrftoken = Cookies.get("csrftoken");
    console.log("csrftoken", csrftoken);

    // returns the stream response from the server
    const eventSource = new SSE(
      `${process.env.REACT_APP_API_BASE_URL}/ai_generator/api/stream_itinerary/`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        payload: JSON.stringify(tripData),
        method: "POST",
      },
    );

    eventSource.onopen = function () {
      console.log("Opened SSE connection.");
    };

    const handleMessage = (event) => {
      try {
        const responseObject = JSON.parse(event.data);
        console.log("Received response:", responseObject);
        // check if the response is the end of the conversation
        if (responseObject.message === "[DONE]") {
          eventSource.close();
          console.log("[DONE] received, closing connection");
          setIsLoading(false);
          onStreamingFinished();
        } else if (responseObject.message === "[ERROR]") {
          eventSource.close();
          console.log("[ERROR] received, closing connection");
          setError("Error processing data");
        }

        // Handle response data
        else {
          setResponse((prev) => ({
            role: prev.role + (responseObject.role || ""),
            content: prev.content + (responseObject.content || ""),
          }));
        }
        setIsLoading(false);
      } catch (parseError) {
        console.error("Error parsing SSE data:", parseError);
        setError("Error parsing data");

        // Optionally close the event source if the error signifies a closed connection
        eventSource.close();
      }
    };

    eventSource.onmessage = handleMessage;
    eventSource.onerror = (eventError) => {
      console.error("Error with SSE connection:", eventError);
      setError("SSE connection error");
      setIsLoading(false);
    };

    return () => {
      eventSource.close();
      console.log("Closed SSE connection.");
    };
  }, []);

  // Function to create hyperlinks from activity data
  const createHyperlinks = (tripData) => {
    const hyperlinksToInserted = [];

    // create breakfast hyperlinks
    if (tripData.breakfast?.spots) {
      for (let i = 0; i < tripData.breakfast.spots.length; i++) {
        const hyperlinkUrl = tripData.breakfast.spots[i].google_maps_url;
        const term = tripData.breakfast.spots[i].name;

        // create data to be stored in hyperlinks array
        const searchTerm = `[R - ${term}]`;
        const escapedSearchTerm = escapeRegExp(searchTerm);
        const hyperlink = `<a href='${hyperlinkUrl}' target='_blank' rel='noopener noreferrer'>${term}</a>`;
        hyperlinksToInserted.push({ searchTerm, escapedSearchTerm, hyperlink });
      }
    }

    // create landmarks hyperlinks
    if (tripData.landmarks?.attractions) {
      for (let i = 0; i < tripData.landmarks.attractions.length; i++) {
        const hyperlinkUrl = tripData.landmarks.attractions[i].webURL;
        const term = tripData.landmarks.attractions[i].title;

        // create data to be stored in hyperlinks array
        const searchTerm = `[R - ${term}]`;
        const escapedSearchTerm = escapeRegExp(searchTerm);
        const hyperlink = `<a href='${hyperlinkUrl}' target='_blank' rel='noopener noreferrer'>${term}</a>`;
        hyperlinksToInserted.push({ searchTerm, escapedSearchTerm, hyperlink });
      }
    }

    // create restaurants hyperlinks
    if (tripData.restaurants?.spots) {
      for (let i = 0; i < tripData.restaurants.spots.length; i++) {
        const hyperlinkUrl = tripData.restaurants.spots[i].google_maps_url;
        const term = tripData.restaurants.spots[i].name;
        // console.log("term", JSON.stringify(term));

        // create data to be stored in hyperlinks array
        const searchTerm = `[R - ${term}]`;
        // console.log("searchTerm", JSON.stringify(term));
        const escapedSearchTerm = escapeRegExp(searchTerm);
        // console.log("escapedSearchterm", JSON.stringify(escapedSearchTerm));
        const hyperlink = `<a href='${hyperlinkUrl}' target='_blank' rel='noopener noreferrer'>${term}</a>`;
        hyperlinksToInserted.push({ searchTerm, escapedSearchTerm, hyperlink });
      }
    }

    return hyperlinksToInserted;
  };

  // Function to escape special characters in a string
  const escapeRegExp = (text) => {
    return text.replace(/[.*+?^${}()|[\]\\"]/g, "\\$&"); // $& means the whole matched string
  };

  // Function to replace terms with hyperlinks
  const replaceTermsWithHyperlinks = (content, hyperlinks) => {
    let updatedContent = content;
    hyperlinks.forEach(({ escapedSearchTerm, hyperlink }) => {
      // check the following:
      const searchRegex = new RegExp(escapedSearchTerm, "g");
      updatedContent = updatedContent.replace(searchRegex, hyperlink);
    });
    return updatedContent;
  };

  if (error) {
    return (
      <p className="error-message">
        Sorry, something went wrong😕
        <br />
        Please refresh this page and try again...
      </p>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="trip-itinerary">
      <h4>
        Here's your {tripData.number_of_days}-day guide for {tripData.location}
      </h4>
      {parsedResponse ? (
        Object.entries(parsedResponse).map(([day, activities]) => (
          <div key={day} className="day-activities">
            <h5>{day}</h5>
            {activities ? (
              Object.entries(activities).map(([activityKey, activityValue]) => (
                <div className="activity-container">
                  {/* Text column */}
                  <div key={activityKey} className="activity-description">
                    {/* Display the activity key as a title or heading */}
                    <strong>{formatActivityKey(activityKey)}</strong>
                    {/* Directly include the activity description */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          activityValue?.description || "Loading activity...",
                      }}
                    />
                  </div>
                  {/* Image column */}{" "}
                  {/* activityValue?.imageSrc - THIS SHOULD BE ADJUSTED TO CHECK tripData*/}
                  {activityKey.startsWith("sightseeing") &&
                  activityValue?.imageSrc ? (
                    <div className="activity-image">
                      <img src={activityValue.imageSrc} alt={activityKey} />
                    </div>
                  ) : null}
                </div>
              ))
            ) : (
              <p></p>
            )}
          </div>
        ))
      ) : (
        <p>Loading your trip guide...</p>
      )}
    </div>
  );
};

export default StreamChatGPT;
